import React from 'react'
import { GoogleMap, useJsApiLoader, Marker, InfoWindow } from '@react-google-maps/api';
import Config from "../Config";

const containerStyle = {
  width: '100%',
  height: '500px'
};

function GoogleMapRenderer(props) {
  // eslint-disable-next-line react/prop-types
  const { lat, lng, address } = props;
  const apiKey = Config.getGoogleMapsKey();
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: apiKey
  });
  const infoWindowRef = React.useRef();
  const center = {
    lat: lat,
    lng: lng
  };

  const [map, setMap] = React.useState(null)

  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);

    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  return isLoaded ? (
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={10}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        <Marker
          position={{
            lat: lat,
            lng: lng
          }}
          clickable
          onClick={() => {
            infoWindowRef.current.open(infoWindowRef.current.state.infoWindow);
          }}
        />
        <InfoWindow
          position={{
            lat: lat,
            lng: lng
          }}
          ref={infoWindowRef}
        >
            <div>{address}</div>
        </InfoWindow>
      </GoogleMap>
  ) : <></>
}

export default React.memo(GoogleMapRenderer)