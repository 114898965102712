/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

function ProductInfo(propsData) {
  const { product } = propsData;
  return (
    <MKBox>
      <MKBox mb={1}>
        <MKTypography variant="h3" fontWeight="bold">
            {product.title}
        </MKTypography>
      </MKBox>
      <MKBox mt={1}>
        <MKTypography variant="h6" fontWeight="medium">
          Price
        </MKTypography>
      </MKBox>
      <MKBox mb={1}>
        <MKTypography variant="h5" fontWeight="medium">
          {product.price_with_currency}
        </MKTypography>
      </MKBox>
      {/* {
        product.stock > 0 ?
            <MKBadge variant="contained" color="success" badgeContent="in stock" container />
        :
            <MKBadge variant="contained" color="danger" badgeContent="Out of stock" container />
      } */}
      {
        product.description !== null && product.description !== '' ?
            <>
                <MKBox mt={3} mb={1}>
                    <MKTypography variant="h6" fontWeight="medium">
                        Description
                    </MKTypography>
                </MKBox>
                <MKBox m={0} mb={2}>
                    <MKBox color="text" fontSize="1.25rem" lineHeight={1}>
                        <MKTypography variant="body2" color="text" fontWeight="regular" verticalAlign="middle">
                            {product.description}
                        </MKTypography>
                    </MKBox>
                </MKBox>
            </>
        :
            <></>
      }
      {
        product.thc !== null && product.thc !== '' ?
            <>
                <MKBox mt={3} mb={1}>
                    <MKTypography variant="button" fontWeight="regular" color="text">
                        <strong>Tetrahydrocannabinol (THC): </strong> {product.thc}
                    </MKTypography>
                </MKBox>
            </>
        :
            <></>
      }
      {
        product.cbd !== null && product.cbd !== '' ?
            <>
                <MKBox mt={3} mb={1}>
                    <MKTypography variant="button" fontWeight="regular" color="text">
                        <strong>Cannabidiol (CBD): </strong> {product.cbd}
                    </MKTypography>
                </MKBox>
            </>
        :
            <></>
      }
      {
        product.strain_type !== null && product.strain_type !== '' ?
            <>
                <MKBox mt={3} mb={1}>
                    <MKTypography variant="button" fontWeight="regular" color="text">
                        <strong>Strain Type: </strong> {product.strain_type}
                    </MKTypography>
                </MKBox>
            </>
        :
            <></>
      }
      {
        product.usage_recommendations !== null && product.usage_recommendations !== '' ?
            <>
                <MKBox mt={3} mb={1}>
                    <MKTypography variant="button" fontWeight="regular" color="text">
                        <strong>Usage Recommendations: </strong> {product.usage_recommendations}
                    </MKTypography>
                </MKBox>
            </>
        :
            <></>
      }
      {
        product.effects !== null && product.effects !== '' ?
            <>
                <MKBox mt={3} mb={1}>
                    <MKTypography variant="button" fontWeight="regular" color="text">
                        <strong>Effects: </strong> {product.effects.split(',').join(', ')}
                    </MKTypography>
                </MKBox>
            </>
        :
            <></>
      }
      {
        product.flavors !== null && product.flavors !== '' ?
            <>
                <MKBox mt={3} mb={1}>
                    <MKTypography variant="button" fontWeight="regular" color="text">
                        <strong>Flavors: </strong> {product.flavors}
                    </MKTypography>
                </MKBox>
            </>
        :
            <></>
      }
      {
        product.stock > 0 ?
            <MKBox mt={5}>
                <Grid item xs={12} lg={5} container>
                    <MKButton variant="gradient" color="info" fullWidth>
                        add to cart
                    </MKButton>
                </Grid>
            </MKBox>
        :
            <></>
      }
    </MKBox>
  );
}

export default ProductInfo;
