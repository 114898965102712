import axios from "axios";
import React, { useEffect } from 'react';
import Api from "../../../../assets/js/utils/Api";
import Cookie from '../../../../assets/js/utils/Cookie';
import { helper } from "../../../../assets/js/utils/Element";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
// @mui material components
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Authentication layout components
import SimpleLayout from "pages/Authentication/components/SimpleLayout";
function Verify() {
    const navigate = useNavigate();
    const { codeHash, emailHash } = useParams();
    const [state, setState] = useState({
        loading: false,
        showError: false,
        errorMessage: "",
    });

    useEffect(() => {
        handleSubmit();
    }, []); 

    const handleSubmit = () => {        
        const source = axios.CancelToken.source();
        setState({
            ...state,
            errorMessage: "",
            showError: false, 
            loading: true, 
            cancelToken: source
        });

        const requestData = {
            hashData: true,
            email: emailHash,
            username: emailHash,
            code: codeHash,
        };
        Api.confirmSignUp(requestData, source).then(data => {
            if(data.hasOwnProperty("access_token")){
                handleLoginSuccess(data);
            }
        }).catch(err => {
            if(typeof(err) === "object"){
                let errors = "";
                if(err.hasOwnProperty("message")){
                    errors = err.message;
                }
                if(err.hasOwnProperty("errors")){
                    errors = err.errors;
                }
                setState({
                    ...state,
                    loading: false, 
                    showError: true, 
                    errorMessage: errors
                });
            }
        });
    }
    const handleLoginSuccess = (data) => {
        Cookie.write("access_token", data.access_token);
        Cookie.write("refresh_token", data.refresh_token);
        Cookie.write("expires_in", data.expires_in);
        
        localStorage.setItem("lastmemory_userinfo", JSON.stringify(data.user));
        let redirectUrl = "/signup/info";
        if(data.user.account_type === 'business'){
            redirectUrl = "/signup/business";
        }
        
        const returnUrl = decodeURIComponent(helper.getParam("return"));
        if(returnUrl && returnUrl !== "null" && returnUrl.length > 0){
            redirectUrl = returnUrl;
        }

        setState({
            ...state,
            loading: false, 
            showError: false, 
            errors: "",
        })

        navigate(redirectUrl);

        // this.props.dispatch({
        //     type: "LOGIN",
        //     state: {
        //         authorized: true,
        //         user: data.user
        //     }
        // });

        // this.props.history.push(redirectUrl);
    }
    const renderErrorMessages = () => {
        const { errorMessage } = state;

        if(typeof(errorMessage) === "object"){
            let errorMessages = [];
            let key = 0;
            for(const attrib in errorMessage){
                const message = errorMessage[attrib];
                errorMessages.push(<MKTypography fontWeight="regular" color="warning" variant="button" lineHeight={"normal"} key={key} className={"sd-error-message passwordCheck-notValid-customizable"}>
                    <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
                    <span className="checkPasswordText-lowerletter"> {message}</span>
                </MKTypography>);
                key++;
            }
            return errorMessages;
        }
        return <MKTypography fontWeight="regular" color="warning" variant="button" lineHeight={"normal"} className={"sd-error-message passwordCheck-notValid-customizable"}>
            <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
            <span className="checkPasswordText-lowerletter"> {errorMessage}</span>
        </MKTypography>;
    }
    return (
        <SimpleLayout>
            <Card>
                <MKBox p={3}>
                    <MKBox textAlign="center">
                        {
                            state.showError === true ?
                                <MKBox mb={2}>
                                    {renderErrorMessages()}
                                </MKBox>
                            :
                                <CircularProgress color="info" size={30} />
                        }
                    </MKBox>
                </MKBox>
            </Card>
        </SimpleLayout>
    );
}

export default Verify;
