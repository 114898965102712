import React from "react";
import axios from "axios";
import Api from "../../../../assets/js/utils/Api";
import Cookie from '../../../../assets/js/utils/Cookie';
import Config from "../../../../Config";
import { helper } from "../../../../assets/js/utils/Element";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

// Authentication layout components
import SimpleLayout from "pages/Authentication/components/SimpleLayout";
import Separator from "pages/Authentication/components/Separator";
import Socials from "pages/Authentication/components/Socials";
import CircularProgress from '@mui/material/CircularProgress';
import { useMaterialUIController } from "context";

function SignInSimple() {
    const [controller, dispatch] = useMaterialUIController();
    const navigate = useNavigate();
    const { authorized } = controller;
    const [rememberMe, setRememberMe] = useState(true);
    const [state, setState] = useState({
        email: '',
        password: '',
        loading: false,
        showError: false,
        errorMessage: "",
        validation: {
            email: '',
            password: '',
            isValid: false
        },
    });

    React.useEffect(() => {
        if(authorized){
            return navigate("/chat", { replace: true });
        }
    });

    const handleSetRememberMe = () => setRememberMe(!rememberMe);

    const handleChange = (event) => {
        let { name, value } = event.target;
        if(name === "email"){
            value = value.toLowerCase();
        }
        setState({
            ...state,
            [name]: value
        });
    };

    const validateForm = () => {
        let validation = {
            email: 'success',
            password: 'success',
            isValid: true
        };
        var emailRegex = /\S+@\S+\.\S+/;
        if(state.email.length <= 0 || !emailRegex.test(state.email)){
            validation.email = "error";
            validation.isValid = false;
        }
        if(state.password.length <= 0){
            validation.password = "error";
            validation.isValid = false;
        }
        setState({
            ...state,
            validation: validation
        });
        return validation.isValid;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const isValid = validateForm();
        if(!isValid || state.loading){
            return;
        }
        const source = axios.CancelToken.source();

        setState({
            ...state,
            loading: true, 
            showError: false, 
            cancelToken: source,
            errors: "",
        });

        const { email, password } = state;
        const requestData = {
            grant_type: "password",
            client_id: Config.getApiClientId(),
            client_secret: Config.getApiClientSecret(),
            username: email,
            password: password,
            scope: ""
        }
        Api.login(requestData, source).then(data => {
            handleLoginSuccess(data, rememberMe);
        }).catch(err => {
            if(typeof(err) === "object"){
                let errors = "";
                if(err.hasOwnProperty("message")){
                    errors = err.message;
                }
                if(err.hasOwnProperty("errors")){
                    errors = err.errors;
                }
                setState({
                    ...state,
                    loading: false, 
                    showError: true, 
                    errorMessage: errors
                });
            }
        });
    }
    const handleLoginSuccess = (data, remember) => {
        if(remember){        
            Cookie.options.duration = '2000';
        }

        Cookie.write("access_token", data.access_token);
        Cookie.write("refresh_token", data.refresh_token);
        Cookie.write("expires_in", data.expires_in);
        
        localStorage.setItem("maryJFinder_userinfo", JSON.stringify(data.user));
        let redirectUrl = "/chat";
        
        const returnUrl = decodeURIComponent(helper.getParam("return"));
        if(returnUrl && returnUrl !== "null" && returnUrl.length > 0){
            redirectUrl = returnUrl;
        }

        setState({
            ...state,
            loading: false, 
            showError: false, 
            errors: "",
        })

        navigate(redirectUrl);

        // this.props.dispatch({
        //     type: "LOGIN",
        //     state: {
        //         authorized: true,
        //         user: data.user
        //     }
        // });

        // this.props.history.push(redirectUrl);
    }
    const renderErrorMessages = () => {
        const { errorMessage } = state;

        if(typeof(errorMessage) === "object"){
            let errorMessages = [];
            let key = 0;
            for(const attrib in errorMessage){
                const message = errorMessage[attrib];
                errorMessages.push(<MKTypography fontWeight="regular" color="warning" variant="button" lineHeight={"normal"} key={key} className={"sd-error-message passwordCheck-notValid-customizable"}>
                    <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
                    <span className="checkPasswordText-lowerletter"> {message}</span>
                </MKTypography>);
                key++;
            }
            return errorMessages;
        }
        return <MKTypography fontWeight="regular" color="warning" variant="button" lineHeight={"normal"} className={"sd-error-message passwordCheck-notValid-customizable"}>
            <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
            <span className="checkPasswordText-lowerletter"> {errorMessage}</span>
        </MKTypography>;
    }
    return (
        <SimpleLayout>
            <Card>
                <MKBox
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                    mx={2}
                    mt={-3}
                    pt={{ xs: 1, md: 2.5}}
                    pb={{ xs: 1.5, md: 2.5}}
                    px={2.5}
                    textAlign="center"
                    lineHeight={1}
                >
                    <MKTypography variant="h4" fontWeight="medium" color="white">
                        Welcome back!
                    </MKTypography>
                </MKBox>
                <MKBox p={3}>
                    <MKBox component="form" role="form" onSubmit={handleSubmit} >
                        {
                            state.showError ?
                                <MKBox mb={2}>
                                    {renderErrorMessages()}
                                </MKBox>
                            :
                                <></>
                        }
                        <MKBox mb={2}>
                            <MKInput 
                                error={state.validation.email === 'error' ? true : false}
                                type="email" 
                                label="Email" 
                                name="email" 
                                value={state.email} 
                                fullWidth 
                                onChange={handleChange} 
                            />
                        </MKBox>
                        <MKBox mb={2}>
                            <MKInput 
                                error={state.validation.password === 'error' ? true : false}
                                type="password" 
                                label="Password" 
                                name="password" 
                                value={state.password}
                                fullWidth 
                                onChange={handleChange} 
                            />
                        </MKBox>
                        <MKBox display="flex" alignItems="center" ml={-1} justifyContent="space-between">
                            <MKBox display="flex" alignItems="center">
                                <Switch checked={rememberMe} onChange={handleSetRememberMe} />
                                <MKTypography
                                    variant="button"
                                    fontWeight="regular"
                                    color="text"
                                    onClick={handleSetRememberMe}
                                    sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                                >
                                    &nbsp;&nbsp;Remember me
                                </MKTypography>
                            </MKBox>
                            <MKBox display="flex" alignItems="center">
                                <MKTypography
                                    component={Link}
                                    to="/forgot"
                                    variant="button"
                                    fontWeight="regular"
                                    color="info"
                                    ml={0}
                                    sx={{ cursor: "pointer", userSelect: "none" }}
                                    textGradient
                                >
                                    Forget Password?
                                </MKTypography>
                            </MKBox>
                        </MKBox>
                        <MKBox mt={3} mb={1}>
                            {
                                state.loading ?
                                    
                                    <MKButton variant="gradient" color="info" fullWidth>
                                        <CircularProgress color="white" size={20} />
                                    </MKButton>
                                :
                                    <MKButton variant="gradient" color="info" fullWidth type="submit">
                                        Login
                                    </MKButton>
                            }
                        </MKBox>
                        <MKBox mt={3} textAlign="center">
                            <MKTypography variant="button" color="text">
                                Don&apos;t have an account?{" "}
                                <MKTypography
                                    component={Link}
                                    to="/signup"
                                    variant="button"
                                    color="info"
                                    fontWeight="regular"
                                    textGradient
                                >
                                    Sign up
                                </MKTypography>
                            </MKTypography>
                        </MKBox>
                        <Separator />
                        <Socials />
                    </MKBox>
                </MKBox>
            </Card>
        </SimpleLayout>
    );
}

export default SignInSimple;
