import axios from "axios";
import Api from "../../../../assets/js/utils/Api";
import { useState } from "react";
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import CircularProgress from '@mui/material/CircularProgress';

// Authentication layout components
import SimpleLayout from "pages/Authentication/components/SimpleLayout";

function Cover() {
    const [state, setState] = useState({
        email: '',
        code: '',
        password: '',
        loading: false,
        showError: false,
        requestCode: false,
        errorMessage: "",
        message: "",
        validation: {
            email: '',
            code: '',
            password: '',
            isValid: false
        },
    });
    const handleChange = (event) => {
        const { name, value } = event.target;
        setState({
            ...state,
            [name]: value
        });
    };
    const validateResetForm = () => {
        let validation = {
            email: 'success',
            password: 'success',
            code: 'success',
            isValid: true
        };
        if(state.code.length <= 3){
            validation.code = "error";
            validation.isValid = false;
        }

        if(state.password.length <= 0){
            validation.password = "error";
            validation.isValid = false;
        }
        setState({
            ...state,
            validation: validation
        });

        return validation.isValid;
    }
    const handleResetPassword = () => {
        const isValid = validateResetForm();
        if(!isValid){
            return;
        }

        const source = axios.CancelToken.source();
        const { email, code, password } = state;
        const requestData = {
            email: email,
            code: code,
            password: password
        };
        setState({
            ...state,
            errorMessage: "",
            loading: true, 
            cancelToken: source, 
            showError: false
        });
        Api.reset(requestData, source).then(data => {
            setState({
                ...state,
                loading: false,
                message: data.message,
                errorMessage: "",
                showError: false
            }); 
        }).catch(err => {
            if(typeof(err) === "object" && err.hasOwnProperty("message")){
                setState({
                    ...state,
                    loading: false, 
                    showError: true, 
                    cancelToken: null,
                    errorMessage: err.message
                });
            }
        });
    }
    const validateForm = () => {
        let validation = {
            email: 'success',
            isValid: true
        };
        var emailRegex = /\S+@\S+\.\S+/;
        if(state.email.length <= 0 || !emailRegex.test(state.email)){
            validation.email = "error";
            validation.isValid = false;
        }
        setState({
            ...state,
            validation: validation
        });
        return validation.isValid;
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        if(state.requestCode === true){
            handleResetPassword();
            return;
        }
        const isValid = validateForm();
        if(!isValid || state.loading){
            return;
        }
        const source = axios.CancelToken.source();

        setState({
            ...state,
            errorMessage: "",
            showError: false, 
            loading: true, 
            cancelToken: source
        });

        const { email } = state;
        const requestData = {
            email: email,
        };
        Api.forgot(requestData, source).then(data => {
            setState({
                ...state,
                loading: false, 
                requestCode: true,
                user: data.user,
                errorMessage: "",
                showError: false, 
            });
        }).catch(err => {
            if(typeof(err) === "object"){
                let errors = "";
                if(err.hasOwnProperty("message")){
                    errors = err.message;
                }
                if(err.hasOwnProperty("errors")){
                    errors = err.errors;
                }
                setState({
                    ...state,
                    loading: false, 
                    showError: true, 
                    errorMessage: errors
                });
            }
        });
    }
    const renderErrorMessages = () => {
        const { errorMessage } = state;

        if(typeof(errorMessage) === "object"){
            let errorMessages = [];
            let key = 0;
            for(const attrib in errorMessage){
                const message = errorMessage[attrib];
                errorMessages.push(<MKTypography fontWeight="regular" color="warning" variant="button" key={key} className={"sd-error-message passwordCheck-notValid-customizable"}>
                    <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
                    <span className="checkPasswordText-lowerletter"> {message}</span>
                </MKTypography>);
                key++;
            }
            return errorMessages;
        }
        return <MKTypography fontWeight="regular" color="warning" variant="button" className={"sd-error-message passwordCheck-notValid-customizable"}>
            <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
            <span className="checkPasswordText-lowerletter"> {errorMessage}</span>
        </MKTypography>;
    }
    return (
        <SimpleLayout>
            <Card>
                <MKBox
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                    mx={2}
                    mt={-3}
                    pt={{ xs: 1, md: 2.5}}
                    pb={{ xs: 1.5, md: 2.5}}
                    px={2.5}
                    textAlign="center"
                    lineHeight={1}
                >
                <MKTypography variant="h4" fontWeight="medium" color="white">
                    Reset Password
                </MKTypography>
                </MKBox>
                <MKBox pt={4} pb={3} px={3}>
                    {
                        state.message !== "" ?
                            <MKBox textAlign="center" lineHeight={1}>
                                <MKTypography fontWeight="regular" variant="button" display="block" mb={2}>
                                    {state.message}
                                </MKTypography>
                                <MKTypography 
                                    component={Link}
                                    to="/login"
                                    variant="button"
                                    color="info"
                                    fontWeight="regular"
                                    textGradient
                                >
                                    Login
                                </MKTypography>
                            </MKBox>
                        :
                            <MKBox component="form" role="form" onSubmit={handleSubmit}>
                                {
                                    state.showError === true ?
                                        <MKBox mb={2}>
                                            {renderErrorMessages()}
                                        </MKBox>
                                    :
                                        <></>
                                }
                                {
                                    state.requestCode === false ?
                                        <MKBox mb={4}>
                                            <MKInput
                                                error={state.validation.email === 'error' ? true : false}
                                                type="email" 
                                                label="Email" 
                                                name="email" 
                                                value={state.email} 
                                                fullWidth 
                                                onChange={handleChange} 
                                            />
                                        </MKBox>
                                    :   
                                        <>
                                            <MKBox textAlign="center" lineHeight={1} mb={4}>
                                                <MKTypography fontWeight="regular" variant="button" display="block">
                                                    We have sent a code by email. Enter it below to confirm your account.
                                                </MKTypography>
                                            </MKBox>
                                            <MKBox mb={2}>
                                                <MKInput
                                                    error={state.validation.code === 'error' ? true : false}
                                                    type="text" 
                                                    label="Verification Code" 
                                                    name="code" 
                                                    value={state.code} 
                                                    fullWidth 
                                                    onChange={handleChange} 
                                                />
                                            </MKBox>
                                            <MKBox>
                                                <MKInput
                                                    error={state.validation.password === 'error' ? true : false}
                                                    type="password" 
                                                    label="New Password" 
                                                    name="password" 
                                                    value={state.password} 
                                                    fullWidth 
                                                    onChange={handleChange} 
                                                />
                                            </MKBox>
                                        </>
                                }
                                
                                <MKBox mt={4} mb={1}>
                                    {
                                        state.loading ?   
                                            <MKButton variant="gradient" color="info" fullWidth>
                                                <CircularProgress color="white" size={20} />
                                            </MKButton>
                                        :
                                            <MKButton variant="gradient" color="info" fullWidth type="submit">
                                                reset
                                            </MKButton>
                                    }
                                </MKBox>
                            </MKBox>
                    }
                </MKBox>
            </Card>
        </SimpleLayout>
    );
}

export default Cover;
