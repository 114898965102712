import axios from "axios";
import Api from "../../../../assets/js/utils/Api";
import Cookie from '../../../../assets/js/utils/Cookie';
import { helper } from "../../../../assets/js/utils/Element";
import Config from "../../../../Config";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

// Authentication layout components
import SimpleLayout from "pages/Authentication/components/SimpleLayout";
import Separator from "pages/Authentication/components/Separator";
import Socials from "pages/Authentication/components/Socials";
import CircularProgress from '@mui/material/CircularProgress';

function Cover() {
    const navigate = useNavigate();
    const [state, setState] = useState({
        email: '',
        password: '',
        terms: false,
        loading: false,
        showError: false,
        errorMessage: "",
        user: null,
        requestCode: false,
        validation: {
            email: '',
            password: '',
            terms: '',
            isValid: false
        },
    });
    const handleChange = (event) => {
        const { name, value } = event.target;
        setState({
            ...state,
            [name]: value
        });
    };
    const handleCheckbox = () => {
        setState({
            ...state,
            terms: !state.terms
        })
    }
    const validateForm = () => {
        let validation = {
            email: 'success',
            password: 'success',
            isValid: true
        };
        var emailRegex = /\S+@\S+\.\S+/;
        if(state.email.length <= 0 || !emailRegex.test(state.email)){
            validation.email = "error";
            validation.isValid = false;
        }
        if(state.password.length <= 0){
            validation.password = "error";
            validation.isValid = false;
        }
        setState({
            ...state,
            validation: validation
        });
        return validation.isValid;
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        const isValid = validateForm();
        if(!isValid || state.loading){
            return;
        }
        const source = axios.CancelToken.source();

        setState({
            ...state,
            errorMessage: "",
            showError: false, 
            loading: true, 
            cancelToken: source
        });

        const { email, password, terms } = state;
        const requestData = {
            email: email,
            password: password,
            terms: terms,
            grant_type: "password",
            client_id: Config.getApiClientId(),
            client_secret: Config.getApiClientSecret(),
            username: email,
            scope: "",
            // account_type: "business"
        };
        Api.signUp(requestData, source).then(data => {
            if(data.hasOwnProperty("access_token")){
                handleLoginSuccess(data);
            }else{
                setState({
                    ...state,
                    loading: false, 
                    requestCode: true,
                    user: data.user,
                    errorMessage: "",
                    showError: false, 
                });
            }
        }).catch(err => {
            if(typeof(err) === "object"){
                let errors = "";
                if(err.hasOwnProperty("message")){
                    errors = err.message;
                }
                if(err.hasOwnProperty("errors")){
                    errors = err.errors;
                }
                setState({
                    ...state,
                    loading: false, 
                    showError: true, 
                    errorMessage: errors
                });
            }
        });
    }
    const handleLoginSuccess = (data) => {
        Cookie.write("access_token", data.access_token);
        Cookie.write("refresh_token", data.refresh_token);
        Cookie.write("expires_in", data.expires_in);
        
        localStorage.setItem("lastmemory_userinfo", JSON.stringify(data.user));
        let redirectUrl = "/signup/info";
        if(data.user.account_type === 'business'){
            redirectUrl = "/signup/business";
        }
        
        const returnUrl = decodeURIComponent(helper.getParam("return"));
        if(returnUrl && returnUrl !== "null" && returnUrl.length > 0){
            redirectUrl = returnUrl;
        }

        setState({
            ...state,
            loading: false, 
            showError: false, 
            errors: "",
        })

        navigate(redirectUrl);

        // this.props.dispatch({
        //     type: "LOGIN",
        //     state: {
        //         authorized: true,
        //         user: data.user
        //     }
        // });

        // this.props.history.push(redirectUrl);
    }
    const resendCode = () => {
        const source = axios.CancelToken.source();
        setState({
            ...state,
            loading: true, 
            showError: false, 
            cancelToken: source
        });
        const requestData = {
            email: state.email,
        };
        Api.resendCode(requestData, source).then(data => {
            console.log(data);
            setState({
                ...state,
                loading: false, 
            });
        }).catch(err => {
            console.log(err);
        });
    }
    const renderErrorMessages = () => {
        const { errorMessage } = state;

        if(typeof(errorMessage) === "object"){
            let errorMessages = [];
            let key = 0;
            for(const attrib in errorMessage){
                const message = errorMessage[attrib];
                errorMessages.push(<MKTypography fontWeight="regular" color="warning" variant="button" lineHeight={"normal"} key={key} className={"sd-error-message passwordCheck-notValid-customizable"}>
                    <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
                    <span className="checkPasswordText-lowerletter"> {message}</span>
                </MKTypography>);
                key++;
            }
            return errorMessages;
        }
        return <MKTypography fontWeight="regular" color="warning" variant="button" lineHeight={"normal"} className={"sd-error-message passwordCheck-notValid-customizable"}>
            <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
            <span className="checkPasswordText-lowerletter"> {errorMessage}</span>
        </MKTypography>;
    }
    return (
        <SimpleLayout>
            <Card>
                <MKBox
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                    mx={2}
                    mt={-3}
                    pt={2.5}
                    pb={2.875}
                    px={2.5}
                    textAlign="center"
                    lineHeight={1}
                >
                    <MKTypography variant="h4" fontWeight="medium" color="white">
                        { state.requestCode ? 'Verify Email' : 'Create an account' }
                    </MKTypography>
                </MKBox>
                <MKBox p={3}>
                    {
                        state.requestCode ?
                            <MKBox textAlign="center" lineHeight={1}>
                                <MKTypography fontWeight="regular" variant="button" display="block" mb={2}>
                                    We sent an email to {state.email}. Click the link to verify your email
                                </MKTypography>
                                {
                                    state.loading ?
                                        <CircularProgress color="info" size={30} />
                                    :
                                        <MKTypography 
                                            component={Link}
                                            variant="button"
                                            color="info"
                                            fontWeight="regular"
                                            textGradient
                                            onClick={resendCode}
                                        >
                                            Resend email
                                        </MKTypography>
                                }
                            </MKBox>
                        :
                            <MKBox component="form" role="form" onSubmit={handleSubmit}>
                                {
                                    state.showError === true ?
                                        <MKBox mb={2}>
                                            {renderErrorMessages()}
                                        </MKBox>
                                    :
                                        <></>
                                }
                                <MKBox mb={2}>
                                    <MKInput 
                                        error={state.validation.email === 'error' ? true : false}
                                        type="email" 
                                        label="Email" 
                                        name="email" 
                                        value={state.email} 
                                        fullWidth 
                                        onChange={handleChange} 
                                    />
                                </MKBox>
                                <MKBox mb={2}>
                                    <MKInput 
                                        error={state.validation.password === 'error' ? true : false}
                                        type="password" 
                                        label="Password" 
                                        name="password" 
                                        value={state.password}
                                        fullWidth 
                                        onChange={handleChange} 
                                    />
                                </MKBox>
                                <MKBox display="flex" alignItems="center" ml={-1}>
                                    <Checkbox 
                                        name="terms" 
                                        onChange={handleCheckbox}
                                        checked={state.terms}
                                        required={true}
                                    />
                                    <MKTypography
                                        variant="button"
                                        fontWeight="regular"
                                        color="text"
                                        sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                                        onClick={handleCheckbox}
                                    >
                                        &nbsp;&nbsp;I agree the&nbsp;
                                    </MKTypography>
                                    <MKTypography
                                        component="a"
                                        href="#"
                                        variant="button"
                                        fontWeight="bold"
                                        color="info"
                                        textGradient
                                        onClick={handleCheckbox}
                                    >
                                        Terms and Conditions
                                    </MKTypography>
                                </MKBox>
                                <MKBox mt={3} mb={1}>
                                    {
                                        state.loading ?
                                            
                                            <MKButton variant="gradient" color="info" fullWidth>
                                                <CircularProgress color="white" size={20} />
                                            </MKButton>
                                        :
                                            <MKButton variant="gradient" color="info" fullWidth type="submit">
                                                Create Account
                                            </MKButton>
                                    }
                                </MKBox>
                                <MKBox mt={3} mb={1} textAlign="center">
                                    <MKTypography variant="button" color="text">
                                        Already have an account?{" "}
                                        <MKTypography
                                            component={Link}
                                            to="/login"
                                            variant="button"
                                            color="info"
                                            fontWeight="regular"
                                            textGradient
                                        >
                                        Login
                                        </MKTypography>
                                    </MKTypography>
                                </MKBox>
                                <Separator />
                                <Socials />
                            </MKBox>
                    }
                </MKBox>
            </Card>
        </SimpleLayout>
    );
}

export default Cover;
