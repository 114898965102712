import Config from '../../../Config'
import axios from 'axios'
import Cookie from './Cookie'

const AuthApi = {
    hasAccessToken: function(){
        if(Cookie.read("access_token") && Cookie.read("access_token").length > 0){
            return true;
        }
        return false;
    },
    validateToken(){
        const that = this;
        return new Promise(function(resolve){
            if(!that.hasAccessToken()){
                return false;
            }
            that.getUserInfo().then(data => {
                return resolve(data);
            }).catch(() => {
                return resolve(false);
            });
        });
    },
    getUserInfo(){
        const that = this;
        return new Promise(function(resolve, reject){
            let userInfo = null;
            if(localStorage.getItem("maryJFinder_userinfo") !== null){
                userInfo = JSON.parse(localStorage.getItem("maryJFinder_userinfo"));
                resolve(userInfo);
            }
            let headers = Config.getApiHeaders();
            
            axios({
                url: Config.getApiUrl()+"/user/me",
                method: "GET",
                headers: headers
            }).then(res => {
                if(res.data.code === 200 || res.data.status_code === 200){
                    if(userInfo === null){
                        localStorage.setItem("maryJFinder_userinfo", JSON.stringify(res.data.body));
                        resolve(res.data.body);
                    }else{
                        localStorage.setItem("maryJFinder_userinfo", JSON.stringify(res.data.body));
                    }
                }else{
                    reject(res.data.body);
                }
            }).catch((err) => {
                if(typeof(err.response) === "object" && err.response.data.code !== 500){
                    that.logout();
                    // window.location.href = "/";
                    reject(err.response.data.body);
                    return;
                }
                reject(err);
            });
        });
    },
    logout: function(){
        let cookies = Cookie.getAll();

        Object.keys(cookies).map(key => {
            Cookie.dispose(key);
            
            return null;
        });
        
        localStorage.removeItem("maryJFinder_userinfo");
    },
};
export default AuthApi;