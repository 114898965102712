/* eslint-disable no-constant-condition */
import React from "react";
import Config from "Config";
import Cookie from '../../assets/js/utils/Cookie';
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Api from "../../assets/js/utils/Api";
import { helper } from "../../assets/js/utils/Element";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import SimpleLayout from "pages/User/components/SimpleLayout";
import MKPaper from '@mui/material/Paper';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import MKInput from "components/MKInput";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import CallIcon from '@mui/icons-material/Call';
import VideoCallIcon from '@mui/icons-material/VideoCall';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SendIcon from '@mui/icons-material/Send';
import InputAdornment from '@mui/material/InputAdornment';
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';

// Material Kit 2 PRO React examples
import MKButton from "components/MKButton";
import CircularProgress from '@mui/material/CircularProgress';
import Markdown from 'react-markdown';
import rehypeExternalLinks from "rehype-external-links";
import Grid from "@mui/material/Grid";
import { styled } from '@mui/system';
import { useMaterialUIController, stateReducer } from "context";
import MKAvatar from "components/MKAvatar";

import assistant from "assets/img/assistant.png";
import logo from "assets/images/mary-j-finder-logo.png";

const StyledChatContainer = styled('div')({
    paddingBottom: "90px",
    "& .markdown-content": {
        fontSize: "16px",
        "& ol": {
            counterReset: 'ordered-listitem'
        },
        "& li": {
            lineHeight: '1.4',
            paddingLeft: '30px',
            position: 'relative'
        },
        "& ul > li": {
            paddingLeft: '10px',
        },
        "& p": {
            lineHeight: '1.5',
            marginBottom: '15px',
            marginTop: 0
        },
        "& ol > li > ul": {
            marginBottom: "15px"
        },
        "& ol > li:after": {
            content: 'counter(ordered-listitem)',
            counterIncrement: 'ordered-listitem',
            left: 0,
            position: 'absolute',
            top: 0,
            background: '#ddd',
            borderRadius: '20px',
            color: '#000',
            fontSize: '12px',
            fontWeight: '400',
            height: '20px',
            lineHeight: '19px',
            paddingTop: '1px',
            textAlign: 'center',
            width: '20px'
        },
        "& ol > li::marker": {
            fontSize: "0px !important"
        },
    },
    "& .message-input": {
        "& .MuiInputBase-root": {
            paddingRight: "50px",
            borderRadius: "30px"
        }
    },
    "& .send-button-container": {
        position: "absolute",
        top: "14px",
        right: "10px",
        "& button": {
            height: '30px',
            width: '30px',
            minHeight: '30px'
        }
    },
    "& .warning": {
        textAlign: "center",
        color: "#7d7d7d",
        fontSize: "10px",
        lineHeight: "1rem",
        marginTop: "10px"
    },
    "& .send-message-container": {
        width: "100%",
        maxWidth: "48rem",
        position: "fixed",
        bottom: "0px",
        paddingBottom: "10px",
        paddingTop: "10px",
        background: "#fff",
        "@media only screen and (max-width: 1024px)": {
            maxWidth: "75%"
        },
        "@media only screen and (max-width: 767px)": {
            maxWidth: "98%"
        }
    },
    "& .chatbot-container": {
        
    },
    "& .chatbot-items-container":{
        gap: "16px",
        maxWidth: "45rem",
        "& button": {
            textTransform: "none",
            textAlign: "left",
            fontSize: "14px",
            background: "#fff",
            border: "1px solid #ddd",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            flexBasis: "0",
            flex: "1 1 0px",
            alignSelf: "stretch",
            padding: "16px",
            "& svg": {
                marginBottom: "8px"
            },
            "@media only screen and (max-width: 480px)": {
                flex: "47%"
            }
        }
    },
});


const Chat = () => {
    const cdnUrl = Config.getCdnUrl();
    const [controller] = useMaterialUIController();
    const { authorized, user } = controller;
    const params = useParams();
    const navigate = useNavigate();
    let threadId = helper.getParam("id");
    if(!threadId || threadId.length <= 0){
        threadId = null;
    }
    if(!threadId && params && params.hasOwnProperty("thread_id")){
        threadId = params.thread_id;
    }

    const [state, setState] = React.useReducer(stateReducer,{
        message: '',
        sending: false,
        userMessage: null,
        response: [],
        threadId: threadId,
        loading: false,
        loaded: false,
        showError: false, 
        errorMessage: null,
        conversation: null,
        messages: [],
        totalItemCount: 0,
        totalPages: 1,
    });

    React.useEffect(() => {
        if(!authorized){
            return navigate("/login", { replace: true });
        }
        if(state.threadId === threadId){
            if(!state.threadId || state.loading || state.loaded){
                return;
            }
        }
        loadMessages();
    }, [threadId]);

    function loadMessages(){
        const source = axios.CancelToken.source();
        let id = state.threadId;
        let newState = {};
        let loading = true;
        if(state.threadId !== threadId){
            id = threadId;
            newState = {
                threadId: id,
                response: [],
                conversation: null,
                messages: [],
                totalItemCount: 0,
                totalPages: 1,
            };
            if(!id){

            }
        }
        const requestData = {
            id: id
        };
        setState({
            type: "update",
            state: {
                loading: loading,
                loaded: false,
                ...newState
            }
        });
        if(!id){
            return;
        }
        Api.getMessages(requestData, source).then(data => {
            setState({
                type: "update",
                state: {
                    conversation: data.conversation,
                    messages: data.response,
                    totalItemCount: data.totalItemCount,
                    totalPages: data.totalPages,
                    loading: false,
                    loaded: false,
                }
            });
        }).catch(err => {
            if(typeof(err) === "object"){
                let errors = "";
                if(err.hasOwnProperty("message")){
                    errors = err.message;
                }
                if(err.hasOwnProperty("errors")){
                    errors = err.errors;
                }
                setState({
                    type: "update",
                    state: {
                        loading: false,
                        loaded: false,
                        showError: true, 
                        errorMessage: errors
                    }
                });
            }
        });        
    }
    function handleChange(event){
        const { name, value } = event.target;
        setState({
            type: "update",
            state: {
                [name]: value
            }
        });
    }
    function parseEvent(input) {
        const event = { type: 'message', data: '' };
        const lines = input.split('\n');
        for (const line of lines) {
            const index = line.indexOf(': ');
            if (index > 0) {
                const field = line.substring(0, index);
                const value = line.substring(index + 2);
    
                if (field === 'event') {
                    event.type = value;
                } else if (field === 'data') {
                    event.data += value + '\n';
                }
            }
        }
        if (event.data) {
            event.data = event.data.trim();
        }
        return event;
    }
    function onSubmit(event, message){
        event.preventDefault();
        const { sending, threadId, messages } = state;
        if(typeof(message) !== "string" || message.length <= 0){
            message = state.message;
        }
        if(sending || message.length <= 0){
            return;
        }
        setState({
            type: "update",
            state: {
                sending: true,
                message: '',
                userMessage: message,
                response: []
            }
        });
        let url = Config.getApiUrl()+"/chatbot/chat?message="+message;
        if(threadId){
            url += "&thread_id="+threadId;
        }
        const token = Cookie.read("access_token");
        axios.get(url, {
            headers: {
                'Accept': 'text/event-stream',
                'Authorization': 'Bearer '+token
            },
            responseType: 'stream',
            adapter: 'fetch'
        }).then(async (response) => {
            const stream = response.data;
            const reader = stream.pipeThrough(new TextDecoderStream()).getReader();
            let messageResponse = state.response;
            while (true) {
                const { value, done } = await reader.read();
                if (done) {
                    setState({
                        type: "update",
                        state: {
                            sending: false
                        }
                    });
                    break;
                }
                const responseMessages = value.split('\n\n');
                responseMessages.forEach((messageRow) => {
                    if (messageRow.trim()) {
                        const event = parseEvent(messageRow);
                        if(event){
                            try{
                                const data = JSON.parse(event.data);
                                let newState = {
                                    userMessage: message,
                                    sending: true
                                };
                                messageResponse.push(data.response);
                                if(event.type === "thread.message" || event.type === 'thread.message.completed'){
                                    messageResponse = [];
                                    messages.push(data);
                                    if(!state.conversation){
                                        newState['threadId'] = data.thread_id;
                                        newState['conversation'] = data.conversation;
                                        newState['totalItemCount'] = messages.length;
                                        newState['totalPages'] = 1;
                                    }
                                    newState['messages'] = messages;
                                    newState['userMessage'] = null;
                                    window.history.replaceState(null, document.title, window.location.pathname+"?id="+data.thread_id);
                                }
                                newState['response'] = messageResponse;
                                setState({
                                    type: "update",
                                    state: newState
                                });
                                window.scrollTo(0, document.body.scrollHeight);
                            }catch(e){}
                        }
                    }
                });
            }            
        });
    }
    function renderMessages(){
        const { messages } = state;
        if(messages.length <= 0){
            return null;
        }
        return messages.map((message, key) => {
            return (
                <MKBox key={key} sx={{ width: '48rem', maxWidth: '100%'}}>
                    <MKBox mt={2}>
                        <ListItem sx={{ display: 'flex', justifyContent: 'space-between', alignItems: "flex-start"  }}>
                            <ListItemText
                                primary={
                                    <MKBox sx={{ display: 'flex', justifyContent: 'flex-end',}}  px={2}>
                                        <MKTypography
                                            sx={{
                                                fontSize: '14px',
                                                backgroundColor: '#fff',
                                                borderRadius: '14px',
                                                padding: '8px',
                                            }}
                                            component="div"
                                        >
                                            {message.message}
                                        </MKTypography>
                                    </MKBox>
                                }
                            />
                            <MKAvatar
                                alt="MaryJFinder"
                                bgColor="info"
                                size={'sm'}
                            />
                        </ListItem>
                    </MKBox>
                    <MKBox mt={2}>
                        <ListItem sx={{ display: 'flex', justifyContent: 'space-between', alignItems: "flex-start" }}>
                            <MKAvatar
                                alt="User"
                                src={assistant}
                                sx={{
                                    backgroundColor: '#fff',
                                    marginRight: "16px"
                                }}
                                size={'sm'}
                            />
                            <ListItemText
                                primary={
                                    <MKBox sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <MKTypography
                                            sx={{
                                                fontSize: '14px',
                                                backgroundColor: '#fff',
                                                borderRadius: '14px',
                                                padding: '8px',
                                                paddingTop: "0px"
                                            }}
                                            component="div"
                                        >
                                            <Markdown className="markdown-content" rehypePlugins={[[rehypeExternalLinks, {target: '_blank'}]]}>{message.response}</Markdown>
                                        </MKTypography>
                                    </MKBox>
                                }
                            />
                        </ListItem>
                    </MKBox>
                </MKBox>
            )
        });
    }
    function renderConversation(){
        const { response, conversation, userMessage } = state;
        return (
            <Grid item xs={12} md={10} lg={8} xl={8}>
                {
                    conversation || userMessage ?
                        <List sx={{ width: '100%', maxWidth: '100%'}}>
                            {renderMessages()}
                            {
                                userMessage !== null ?
                                    <MKBox key="usermessage" sx={{ width: '48rem', maxWidth: '100%'}}>
                                        <MKBox mt={2}>
                                            <ListItem sx={{ display: 'flex', justifyContent: 'space-between', alignItems: "flex-start"  }}>
                                                <ListItemText
                                                    primary={
                                                        <MKBox sx={{ display: 'flex', justifyContent: 'flex-end',}}  px={2}>
                                                            <MKTypography
                                                                sx={{
                                                                    fontSize: '14px',
                                                                    backgroundColor: '#fff',
                                                                    borderRadius: '14px',
                                                                    padding: '8px',
                                                                }}
                                                                component="div"
                                                            >
                                                                {userMessage}
                                                            </MKTypography>
                                                        </MKBox>
                                                    }
                                                />
                                                <MKAvatar
                                                    alt="MaryJFinder"
                                                    bgColor="info"
                                                    size={'sm'}
                                                />
                                            </ListItem>
                                        </MKBox>
                                        {
                                            response.length > 0 ?
                                                <MKBox mt={2} key="userResponse">
                                                    <ListItem sx={{ display: 'flex', justifyContent: 'space-between', alignItems: "flex-start"  }}>
                                                        <MKAvatar
                                                            alt="User"
                                                            src={assistant}
                                                            sx={{
                                                                backgroundColor: '#fff',
                                                                marginRight: "16px"
                                                            }}
                                                            size={'sm'}
                                                        />
                                                        <ListItemText
                                                            primary={
                                                                <MKBox sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                    <MKTypography
                                                                        sx={{
                                                                            fontSize: '14px',
                                                                            backgroundColor: '#fff',
                                                                            borderRadius: '14px',
                                                                            padding: '8px',
                                                                            paddingTop: "0px"
                                                                        }}
                                                                        component="div"
                                                                    >
                                                                        <Markdown className="markdown-content" rehypePlugins={[[rehypeExternalLinks, {target: '_blank'}]]}>{response.join('')}</Markdown>
                                                                    </MKTypography>
                                                                </MKBox>
                                                            }
                                                        />
                                                    </ListItem>
                                                </MKBox>
                                            :
                                            null
                                        }
                                    </MKBox>
                                :
                                null
                            }
                        </List>
                    :
                    <MKBox
                            mt={{ xs: 0, sm: 3 }}
                            position="relative"
                            container
                            className="chatbot-container"
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                flexDirection: "column",
                                alignItems:"center"
                            }}
                        >
                        <MKAvatar
                            alt="User"
                            src={logo}
                            sx={{
                                backgroundColor: '#fff',
                                marginRight: "16px",
                                display: (state.threadId === null ? "" : "none")
                            }}
                            size={'xxl'}
                            variant="rounded"
                        />
                        <MKBox
                            mt={3}
                            container
                            className="chatbot-items-container"
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                flexDirection: "row",
                                alignItems:"center",
                                flexWrap:"wrap"
                            }}
                        >
                            {
                                state.threadId === null ?
                                    <>
                                        <MKButton color="light" onClick={(event) => onSubmit(event, "What products do you recommend for anxiety?")}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 24 24" style={{color: "rgb(226, 197, 65)"}}><path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19a3 3 0 1 1-6 0M15.865 16A7.54 7.54 0 0 0 19.5 9.538C19.5 5.375 16.142 2 12 2S4.5 5.375 4.5 9.538A7.54 7.54 0 0 0 8.135 16m7.73 0h-7.73m7.73 0v3h-7.73v-3"></path></svg>
                                            What products do you recommend for anxiety?
                                        </MKButton>
                                        <MKButton color="light" onClick={(event) => onSubmit(event, "Where is the closest dispensary to my location?")}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 24 24" style={{color: "rgb(118, 208, 235)"}}><path fill="currentColor" fillRule="evenodd" d="M12.455 4.105a1 1 0 0 0-.91 0L1.987 8.982 1.077 7.2l9.56-4.877a3 3 0 0 1 2.726 0l9.56 4.877A1.98 1.98 0 0 1 24 9.22V15a1 1 0 1 1-2 0v-3.784l-2.033.995v4.094a3 3 0 0 1-1.578 2.642l-4.967 2.673a3 3 0 0 1-2.844 0l-4.967-2.673a3 3 0 0 1-1.578-2.642v-4.094l-2.927-1.433C-.374 10.053-.39 7.949 1.077 7.2l.91 1.782 9.573 4.689a1 1 0 0 0 .88 0L22 8.989v-.014zM6.033 13.19v3.114a1 1 0 0 0 .526.88l4.967 2.674a1 1 0 0 0 .948 0l4.967-2.673a1 1 0 0 0 .526-.88V13.19l-4.647 2.276a3 3 0 0 1-2.64 0z" clipRule="evenodd"></path></svg>
                                            Where is the closest dispensary to my location?
                                        </MKButton>
                                        <MKButton color="light" onClick={(event) => onSubmit(event, "What products are available for pain relief?")}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 24 24" style={{color: "rgb(118, 208, 235)"}}><path fill="currentColor" fillRule="evenodd" d="M13.997 3.39A2.5 2.5 0 0 1 17.2 2.103l2.203.882a2.5 2.5 0 0 1 1.342 3.369L19.063 10H20a1 1 0 0 1 1 1v8a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3v-8a1 1 0 0 1 .992-1l-.149-.101-.03-.022c-1.254-.924-1.016-2.864.425-3.458l2.12-.874.724-2.176c.492-1.479 2.41-1.851 3.42-.665L11.99 4.45l1.521.01zm1.513 1.506a2 2 0 0 1 .461 2.618l-1.144 1.861v.045a1.3 1.3 0 0 0 .044.278 1 1 0 0 1 .047.302h1.942l2.07-4.485a.5.5 0 0 0-.268-.673l-2.203-.882a.5.5 0 0 0-.641.258zM12.889 10a3.3 3.3 0 0 1-.06-.499c-.01-.236-.004-.69.237-1.081l1.202-1.954-2.293-.016a2 2 0 0 1-1.51-.704L8.98 4l-.725 2.176A2 2 0 0 1 7.12 7.394L5 8.267l2.063 1.407c.129.087.23.2.303.326zM5 12v7a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-7zm4.5 2.5a1 1 0 0 1 1-1h3a1 1 0 1 1 0 2h-3a1 1 0 0 1-1-1" clipRule="evenodd"></path></svg>
                                            What products are available for pain relief?
                                        </MKButton>
                                        <MKButton color="light" onClick={(event) => onSubmit(event, "Can you recommend a dispensary near me?")}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 24 24" style={{color: "rgb(203, 139, 208)"}}><path stroke="currentColor" strokeLinecap="round" strokeWidth="2" d="M3 6h7M3 10h4"></path><path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13.428 17.572 20.5 10.5a2.828 2.828 0 1 0-4-4l-7.072 7.072a2 2 0 0 0-.547 1.022L8 19l4.406-.881a2 2 0 0 0 1.022-.547"></path></svg>
                                            Can you recommend a dispensary near me?
                                        </MKButton>
                                    </>
                                :
                                    <CircularProgress color="info" size={40} />
                            }
                        </MKBox>
                    </MKBox>
                }
            </Grid>
        )
    }
    const { message, sending } = state;
    return (
        <SimpleLayout wide="full">
            <StyledChatContainer>
                <MKBox
                    pl={1}
                    pr={1}
                    container
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: "column",
                        alignItems:"center"
                    }}
                >
                    {renderConversation()}
                    <MKBox mt={3} position="relative" className="send-message-container">
                        <MKInput 
                            type="text" 
                            label="Message"
                            placeholder="Enter your message..."
                            name="message"
                            value={message} 
                            fullWidth 
                            // multiline
                            // rows={3}
                            onChange={(event) => handleChange(event)}
                            className="message-input"
                        />
                        <div className="send-button-container">
                            {
                                sending ?
                                    <MKButton size="small" iconOnly variant="gradient" color="info">
                                        <CircularProgress color="white" size={20} />
                                    </MKButton>
                                :
                                    <MKButton size="small" iconOnly variant="gradient" color="info" onClick={(event) => onSubmit(event, '')}>
                                        <SendIcon />
                                    </MKButton>
                            }
                        </div>
                        <p className="warning">MaryJFinder can make mistakes. Check important info.</p>
                    </MKBox>
                </MKBox>
            </StyledChatContainer>
        </SimpleLayout>
    );
}

export default Chat;
