/**
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

import logo from "assets/images/mary-j-finder-logo.png";

function SimpleLayout({ wide, children }) {
    return (
        <Container>
            <MKBox 
                component="img"
                src={logo}
                alt="Mary J Finder"
                width="140px"
                mx="auto"
                mb={8}
                pt={3}
                display="block"
            />
            <MKBox display="flex" flexDirection="column" justifyContent="space-between" pb={5}>
                <MKBox width="100%" mx="auto" position="relative" zIndex={2}>
                    <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
                        {
                            wide === 'full' ?
                                <Grid item xs={12}>
                                    {children}
                                </Grid>
                            : wide === 'medium' ?
                                <Grid item xs={12} md={10} lg={8} xl={6}>
                                    {children}
                                </Grid>
                            :
                                <Grid item xs={12} sm={8} lg={6} xl={4}>
                                    {children}
                                </Grid>
                        }
                    </Grid>
                </MKBox>
            </MKBox>
        </Container>
    );
}

SimpleLayout.defaultProps = {
    wide: ''
}
SimpleLayout.propTypes = {
    children: PropTypes.node.isRequired,
    wide: PropTypes.string,
};

export default SimpleLayout;
