/**
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import AuthApi from "./assets/js/utils/Auth";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

// Material Kit 2 PRO React themes
import theme from "assets/theme";

// Material Kit 2 PRO React routes
import routes from "routes";
import { useMaterialUIController } from "context";

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const { pathname } = useLocation();
  const hasAccessToken = AuthApi.hasAccessToken();
  const { authorized, loading } = controller;

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;

    if(!authorized){
        AuthApi.validateToken().then((isValidToken) => {
            if(hasAccessToken === true && isValidToken === false){
                AuthApi.logout();
                dispatch({type: 'LOGOUT', value: {authorized: false, user: null, loading: false}});
            }else if(hasAccessToken === true){     
                dispatch({
                    type: "LOGIN",
                    value: {authorized: true, user: isValidToken, loading: false}
                });
            }
        }).catch(() => {
            dispatch({
                type: "UPDATE_STATE",
                value: {loading: false}
            });
        });
    }
    
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {
        loading ?
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={true}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        :
        <Routes>
            {getRoutes(routes)}
            {
                authorized ?
                    <Route path="*" element={<Navigate to="/chat" />} />
                :
                    <Route path="*" element={<Navigate to="/login" />} />
            }
            
        </Routes>
      }
    </ThemeProvider>
  );
}
