import React from "react";
import axios from "axios";
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import AuthApi from "../../../../assets/js/utils/Auth";
import Api from "../../../../assets/js/utils/Api";

// import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKAvatar from "components/MKAvatar";
import logo from "assets/images/mary-j-finder-logo.png";
import userRoutes from "userRoutes";
import CustomNavbarDropdown from "examples/Navbars/DefaultNavbar/CustomNavbarDropdown";
import CustomNavbarMobile from "examples/Navbars/DefaultNavbar/CustomNavbarMobile";
import { NotificationsActive } from "@mui/icons-material";
import { useState, useEffect } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import breakpoints from "assets/theme/base/breakpoints";
import Icon from "@mui/material/Icon";
import { useMaterialUIController, stateReducer } from "context";
import { useNavigate } from "react-router-dom";

import styles from "assets/css/custom.module.css";

function SimpleLayout({ wide, children }) {
    const [controller, dispatch] = useMaterialUIController();
    const { authorized, user } = controller;
    const navigate = useNavigate();
    const [dropdown, setDropdown] = useState(null);
    const [mobileNavbar, setMobileNavbar] = useState(false);
    const [mobileView, setMobileView] = useState(false);
    
    const openDropdown = ({ currentTarget }) => setDropdown(currentTarget);
    const closeDropdown = () => setDropdown(null);
    const openMobileNavbar = () => setMobileNavbar(!mobileNavbar);
    let newChatRoute = {
        key: "newchat",
        name: "New Chat",
        href: null,
        route: "/chat",
        collapse: false,
        routeCollapses: false
    };
    let routes = [];
    if(authorized){
        routes.push(newChatRoute);
    }

    const [state, setState] = React.useReducer(stateReducer,{
        response: [],
        loading: false,
        loaded: false,
        showError: false, 
        errorMessage: null,
        totalItemCount: 0,
        totalPages: 1,
        userRoutes: routes
    });

    useEffect(() => {
        // A function that sets the display state for the DefaultNavbarMobile.
        function displayMobileNavbar() {
          if (window.innerWidth < breakpoints.values.lg) {
            setMobileView(true);
            setMobileNavbar(false);
          } else {
            setMobileView(false);
            setMobileNavbar(false);
          }
        }
    
        /** 
         The event listener that's calling the displayMobileNavbar function when 
         resizing the window.
        */
        window.addEventListener("resize", displayMobileNavbar);
    
        // Call the displayMobileNavbar function to set the state with the initial value.
        displayMobileNavbar();

        if(authorized && (!state.loading && !state.loaded)){
            loadConversations();
        }
    
        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", displayMobileNavbar);
    }, []);

    const handleLogout = () => {
        setDropdown(null);

        AuthApi.logout();
        dispatch({type: 'LOGOUT', value: {authorized: false, user: null, loading: false}});
        navigate("/login", { replace: true });
    };

    function loadConversations(){
        const source = axios.CancelToken.source();
        const requestData = {};
        setState({
            type: "update",
            state: {
                loading: true,
                loaded: false,
            }
        });
        Api.getConversations(requestData, source).then(data => {
            let userRoutes = [newChatRoute];
            data.response.map((conversation) => {
                userRoutes.push({
                    key: conversation.thread_id,
                    name: conversation.message,
                    href: null,
                    route: "/chat/"+conversation.thread_id,
                    collapse: false,
                    routeCollapses: false
                });
            });
            setState({
                type: "update",
                state: {
                    response: data.response,
                    totalItemCount: data.totalItemCount,
                    totalPages: data.totalPages,
                    loading: false,
                    loaded: false,
                    userRoutes: userRoutes
                }
            });
        }).catch(err => {
            if(typeof(err) === "object"){
                let errors = "";
                if(err.hasOwnProperty("message")){
                    errors = err.message;
                }
                if(err.hasOwnProperty("errors")){
                    errors = err.errors;
                }
                setState({
                    type: "update",
                    state: {
                        loading: false,
                        loaded: false,
                        showError: true, 
                        errorMessage: errors
                    }
                });
            }
        });        
    }
    
    const renderNavbarItems = state.userRoutes.map(({ key, name, href, route }) => (
        <CustomNavbarDropdown
            key={key}
            name={name}
            href={href}
            route={route}
            light={false}
            icon={<></>}
            collapse={false}
        />
    ));

    return (
        <Grid container spacing={1} justifyContent="center" alignItems="normal" height="100%">
            <Grid item xs={0} md={3} lg={3} xl={2}>
                <MKBox
                    className={styles.mobileMenuBtn}
                    display={{ xs: "inline-block", md: "none" }}
                    lineHeight={0}
                    p={1.5}
                    color={mobileNavbar ? "black" : "black"}
                    sx={{ cursor: "pointer" }}
                    onClick={openMobileNavbar}
                >
                    <Icon fontSize="default">{mobileNavbar ? "close" : "menu"}</Icon>
                </MKBox>
                {
                    mobileView && mobileNavbar?
                        <MKBox
                            className={styles.mobileMenu}
                            bgColor={"white"}
                            shadow={"lg"}
                            borderRadius="none"
                            px={2}
                        >
                            <CustomNavbarMobile routes={state.userRoutes} open={mobileNavbar} onClose={(status) => setMobileNavbar(status)} />
                        </MKBox>
                    :
                        <MKBox
                            color="inherit"
                            display={{ xs: "none", md: "flex" }}
                            flexDirection={"column"}
                            ml="auto"
                            mr={"auto"}
                        >
                            <MKBox 
                                component="img"
                                src={logo}
                                alt="Mary J Finder"
                                width="100px"
                                ml={3}
                                mb={3}
                                pt={3}
                                display="block"
                            />
                            {renderNavbarItems}
                        </MKBox>
                }
            </Grid>
            <Grid item xs={12} md={9} lg={9} xl={10} className={styles.dashboardRightSidebar}>
                <MKBox width="100%" display="flex" justifyContent="flex-end" alignItems="center" p={2}>
                    <NotificationsActive fontSize="large" />
                    <MKBox pl={3}>
                        <MKAvatar
                            className={styles.cursorPointer}
                            variant={"circular"}
                            bgColor="info"
                            onClick={openDropdown}
                            size={'sm'}
                        />
                        <Menu
                            anchorEl={dropdown}
                            open={Boolean(dropdown)}
                            onClose={closeDropdown}
                        >
                            <MenuItem onClick={handleLogout}>Logout</MenuItem>
                        </Menu>
                    </MKBox>
                </MKBox>
                <MKBox display="flex" flexDirection="column" justifyContent="space-between" px={2} pt={5} pb={2}>
                    <MKBox width="100%" mx="auto" position="relative" zIndex={2}>
                        <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
                            {
                                wide === 'full' ?
                                    <Grid item xs={12}>
                                        {children}
                                    </Grid>
                                : wide === 'medium' ?
                                    <Grid item xs={12} md={10} lg={8} xl={6}>
                                        {children}
                                    </Grid>
                                :
                                    <Grid item xs={12} sm={8} lg={6} xl={4}>
                                        {children}
                                    </Grid>
                            }
                        </Grid>
                    </MKBox>
                </MKBox>
            </Grid>
        </Grid>
    );
}

SimpleLayout.defaultProps = {
    wide: ''
}
SimpleLayout.propTypes = {
    children: PropTypes.node.isRequired,
    wide: PropTypes.string,
};

export default SimpleLayout;
